var Section = {
	top: 0,
	show: 0,
	id: ""
}
var sections = [];
var page = {
	current	: "",
	next 	: "",
	id 		: "",
	clickFlag: true,
	first 	: true,
	init: function(){
		page.checkUrl();
		page.nav();
		window.addEventListener('popstate', function (e) {
			page.checkUrl();
		});
	},
	nav: function(){
		$("#technav a").off("click").on("click", function(e){
			$("#technav a").removeClass("active");
			$(this).addClass("active");
			e.preventDefault();
			let link = $(this).attr("href");
			page.push(link);
		});
	},
	push: function(link){
		window.history.pushState(null, null, link);
		if(page.clickFlag){
			page.clickFlag = false;
			page.checkUrl();
		}
	},
	checkBox: function(){
		sections = [];
		$(".section").each(function(){
			var _s 	= object(Section);
			_s.top 	= Math.floor($(this).offset().top);
			_s.show = Math.floor(_s.top + $(window).height()/3);
			_s.id 	= $(this).attr("id");
			sections.push(_b);
		});
	},
	afterLoadPage: function(){
		page.clickFlag = true;
		$("#technav a").off("click").on("click", function(e){
			$("#technav a").removeClass("active");
			$(this).addClass("active");
			e.preventDefault();
			let link = $(this).attr("href");
			page.push(link);
		});
		//boxのoffset.topを求めてスクロールでアニメーション
		this.checkBox();
		setTimeout(function(){
			page.checkBox();
		}, 300);
	},
	changeContent: function(data){
		//console.log(data);
		var defer 			= $.Deferred();
		var out_html 		= $($.parseHTML(data)),
			ttl 			= out_html.filter("title")[0].innerHTML,
			$content 		= $("#load_technology"),
			contentHTML 	= out_html.filter("#load_technology")[0].innerHTML;

		console.log(contentHTML)
		$content.html(contentHTML);
		document.title = ttl;
		var imgArry = [];
		$("img", $content).each(function(){
			imgArry.push($(this).attr("src"));
		});
		//console.log(imgArry)
		if(imgArry > 0){
			preload.startLoad(imgArry.length,imgArry).done(function(){
				setTimeout(function(){
					defer.resolve();
				},50);
			});
		}else{
			setTimeout(function(){
				defer.resolve();
			},50);
		}
		
		return defer.promise();
	},
	changePage: function(){
		var $body 	= $("body"),
			$load 	= $("#load_technology"),
			$page 	= $("html,body"),
			ease 	= "easeInOutCubic";

		$load.addClass("disable");
		var posY = $("#technav").offset().top - $("header").outerHeight();
		if($(document).scrollTop() != posY){
			$page.animate({
				scrollTop : posY + "px"
			},{
				"duration" 	: 600,
				"easing" 	: ease
			});
		}
		
		$load.off().on(transitionend,function(){
			$load.off();
			$.get(page.directory,{},function(data){
				page.changeContent(data).done(function(){
					$load.removeClass("disable");
					$load.off().on(transitionend,function(){
						$load.off();
						page.afterLoadPage();
					});
				});
			});
		});
	},
	checkUrl : function(){
		var url 		= location.href;
		var prop 		= location.pathname;
		var propArry 	= prop.split("/");
		page.directory 	= prop;
		page.filename 	= propArry[propArry.length-1];
		page.directoryNum = propArry.length;

		console.log(page.directory, page.filename, page.directoryNum);
		if(page.directoryNum === 3){
			//下層
			if(!page.first){
				page.changePage();
			}else{
				page.first = false;
			}
		}
	}
}
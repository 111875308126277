var access = {};
access.gMap = function(i){
	var gmg = new google.maps.Geocoder(),map, center, mk;
	var address = $(".gmap:eq(" + i + ")").attr("data-address");

	gmg.geocode({
			'address': address
		},
		function(results, status) {
			if (status == google.maps.GeocoderStatus.OK) {
				//ジオコードが成功したかどうかチェック
				center = results[0].geometry.location;
				initialize();
				mk = new google.maps.Marker({//ここからマーカーの設定
					map: map,
					position: results[0].geometry.location
				});
				/*mapHTML = '';
				var infoWindowOpts = {
					position : new google.maps.LatLng(35.694912, 139.776344),
					content: mapHTML
				}
				var info = new google.maps.InfoWindow(infoWindowOpts);
				info.open(map);
				*/
			}
		}
	);

	function initialize() {//初期化
		if(window.matchMedia('all and (min-width : 864px)').matches) {
			var image = {
				url : '/wp-content/themes/qosmo_theme/images/icon_pin.png',
				scaledSize : new google.maps.Size(69, 93)
			}
		} else if(window.matchMedia('all and (min-width : 0px)').matches) {
			var image = {
				url : '/wp-content/themes/qosmo_theme/images/icon_pin.png',
				scaledSize : new google.maps.Size(35, 46)
			}
		}
		var marker = new google.maps.Marker({ //ここからマーカーの設定
			map 	: map,
			position: center
		});
		var options = {
			center: center,
			zoom: 15,
			mapTypeId: google.maps.MapTypeId.ROADMAP,
			scrollwheel: false,
			streetViewControl: false,
			scaleControl: false,
			zoomControl: true,
			overviewMapControl: false,
			mapTypeControl: false,
			draggable: false,
			panControlOptions: {
				position: google.maps.ControlPosition.TOP_RIGHT
			},
			zoomControlOptions: {
				position: google.maps.ControlPosition.TOP_RIGHT
			},
			mapTypeControlOptions: {
				position: google.maps.ControlPosition.TOP_CENTER
			},
			styles:[]
		};

		var map = new google.maps.Map($(".gmap:eq(" + i + ")").get(0), options);//セレクタ内に地図取得
		marker.setMap(map);
		google.maps.event.addDomListener(window, 'resize', function() {
			map.setCenter(center);
		});
	}
}
$(function(){
	if(isPage === "company"){
		$(".gmap").each(function(i){
			access.gMap(i);
		});
	}
});

var Animate = {
	top: 0,
	show: 0,
	id: 0
}
var animations = [];

var isPage = "";
var common = {
	init: ()=>{
		var resizeFlag;
		var resizeTimer = null;
		var $body = $("body");
		common.resize();
		common.nav();
		if(isPage === "index"){
			kawatan.init();
		}else if(isPage === "technology"){
			page.init();
		}else if(isPage === "contact"){
			$('.wpcf7').off('wpcf7:mailsent').on('wpcf7:mailsent', function() {
				$("main").addClass("complete");
			});
		}
		if(isPage != "index"){
			$("body").removeClass("disable");
		}
		/* window resize
		----------------------------- */
		var resizeCallback = function() {
			common.resize();
			resizeTimer = null;
		};

		$(window).off("resize").on("resize",function(){
			if(!$body.hasClass("resize")){
				$body.addClass("resize");
			}
			if(resizeTimer) {
				clearTimeout(resizeTimer);
			}
			resizeTimer = setTimeout(resizeCallback, 300);
		});
		$(document).on("scroll", function(){
			common.scrl();
		});
	},
	nav: ()=>{
		var $page 	= $("html,body"),
			ease 	= "easeInOutCubic",
			$body 	= $("body");
		//share
		$(".sharewith li a").off().on("click", function(){
			$this = $(this);
			var idx = $(".sharewith li a").index(this);
			var url = $(this).attr("data-share");
			shareTxt(idx,url);
		});
		$("a#btn_menu").off().on("click", function(){
			if(!$body.hasClass("show_glbnav")){
				$body.addClass("show_glbnav");
			}else{
				$body.removeClass("show_glbnav");
			}
		});
		$("#btn_close").off().on("click", function(){
			if($body.hasClass("show_glbnav")){
				$body.removeClass("show_glbnav");
			}
		});
		$(".pagetop a").off().on("click", function(){
			$page.animate({
				scrollTop : "0px"
			},{
				"duration" 	: 1000,
				"easing" 	: ease
			});
		});
		function shareTxt(num,shareUrl){
			var domain = "http://" + document.domain;
			var url 	= domain + shareUrl;
			if(num === 0){
				//Twitter
				var txt = $this.attr("data-text");
				window.open(
					'http://twitter.com/share?url=' + encodeURIComponent(url) + "&amp;text=" + encodeURIComponent(txt),
					'twittersharedialog',
					'width=626,height=436');
			}else if(num === 1){
				//Facebook
				window.open(
					'http://www.facebook.com/sharer/sharer.php?u=' + encodeURIComponent(url),
					'facebooksharedialog',
					'width=626,height=436');
			}else{
				//LINE
				var txt = $this.attr("data-text") + "\n" + url;
				window.open(
					'http://line.me/R/msg/text/?' + encodeURIComponent(txt),
					'linesharedialog',
					'width=626,height=436');
			}
		}
	},
	checkBlock: function(){
		animations = [];
		$(".a").each(function(){
			var _a 	= object(Animate);
			_a.top 	= Math.floor($(this).offset().top);
			_a.show = Math.floor(_a.top - $(window).height()/3);
			_a.id 	= $(this).attr("id");
			animations.push(_a);
		});
	},
	resize: ()=>{
		var winW = window.innerWidth;
		var winH = window.innerHeight;
		var $body = $("body");
		if(isPage === "index"){
			kawatan.checkBlock();
		}
		common.checkBlock();
		setTimeout(function(){
			if($body.hasClass("resize")){
				$body.removeClass("resize");
			}
		},300);
	},
	scrollToPosition: (id,callback) =>{
		var $page 	= $("html,body"),
			ease 	= "easeInOutCubic",
			flag 	= true;
		$page.animate({
			scrollTop : "0px"
		},{
			"duration" 	: 1000,
			"easing" 	: ease,
			"complete"	: function(){
				if(flag){
					flag = false;
				}
			}
		});
	},
	scrl: () =>{
		var topD = $(document).scrollTop(),
			btmD 	= topD + $(window).height(),
			len 	= animations.length,
			$body 	= $("body");
	
		for(var i = 0; i < len; i++){
			if(i === len - 1){
				if(btmD >= animations[i].show){
					addAnimation(i)
				}
			}else{
				if(btmD >= animations[i].show){
					addAnimation(i)
				}
			}
		}
		if(isPage === "index"){
			if(topD >= $("#blk_main").height()){
				if(!$body.hasClass("shownav")){
					$body.addClass("shownav");
				}
			}else{
				if($body.hasClass("shownav")){
					$body.removeClass("shownav");
				}
			}
		}
		function addAnimation(i){
			var $blk = $(".a").eq(i);
			//removeAnimation();
			if(!$blk.hasClass("animate")){
				//removeotherAnimation(j);
				$blk.addClass("animate");
				setTimeout(function(){
					$blk.addClass("animated");
				},1000);
			}
		}

	}
}
document.addEventListener('DOMContentLoaded', function () {
	isPage = $("body").attr("id");
	common.init();
});